/*******************
 * Script Imports
 *******************/
import $ from "jquery";
window.$ = window.jQuery = $;

import "popper.js";
import "bootstrap";
import "slick-slider";
// import "bootstrap-table/dist/bootstrap-table.js";
import "./blocks/image-slider/image-slider";
import "./blocks/tabs/tabs";

import "./vendor/vendor";
import "./utility/detect-ie";
import "./utility/is-touch-device";
import "./utility/acf-google-map";
import "./theme/scripts";
import "./theme/on-load.js";
import "./theme/on-scroll.js";
import "./theme/on-resize.js";

/*************************************
 * MJ BLOCKS
 ************************************/
import "./mj-blocks/cards";
import "./mj-blocks/resources-list";
import "./mj-blocks/tabs-slider";
import "./mj-blocks/header-image";
/*******************
 * Style Imports
 *******************/
import "../scss/main.scss";
