import $ from "jquery";
import slick from "slick-slider";
window.$ = window.jQuery = $;

function slickInit() {
  $(".tabs-slider-wrapper").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  });
}

$(document).ready(function() {
  slickInit();

  $(".nav-tabs a").on("shown.bs.tab", function() {
    $(".tabs-slider-wrapper").slick("unslick");
    slickInit();
  });
});
