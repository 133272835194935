import SVGInjector from "svg-injector";
$(document).ready(function() {
  console.log("Theme scripts working");

  $(".link-icon a").popover();

  //SVG Injector class for icons
  var mySVGsToInject = document.querySelectorAll("img.svg-inject");
  SVGInjector(mySVGsToInject);

  //Get submenu height
  $(".menu-item > .sub-menu > .menu-item-has-children").hover(
    function() {
      var parentHeight = $(this)
        .parent()
        .outerHeight();
      var childHeight = $(this)
        .find(".sub-menu")
        .outerHeight();

      if (parentHeight > childHeight) {
        $(this)
          .find(".sub-menu")
          .css("height", parentHeight + "px");
      } else if (childHeight > parentHeight) {
        $(this)
          .parent()
          .css("height", childHeight + "px");
      }
    },
    function() {}
  );

  $(".hamburger").click(function() {
    $(this).toggleClass("is-active");
    $(".menu-wrapper").toggleClass("mobile-show");
  });
  if ($(window).innerWidth() + 15 < 768) {
    $(".menu > .menu-item-has-children > a").click(function(e) {
      if (
        !$(this)
          .parents("li")
          .hasClass("mobile-open")
      ) {
        e.preventDefault();
        $(this)
          .parents("li")
          .addClass("mobile-open");
      }
    });
    $(".arrow-up").click(function() {
      $(this)
        .parent("li.mobile-open")
        .removeClass("mobile-open");
    });
    $(".arrow-down").click(function() {
      $(this)
        .parent("li")
        .addClass("mobile-open");
    });
  }

  $(".single-associate .core-paragraph")
    .first()
    .addClass("first-core-paragraph");
  $("<div class='circle-cutout'></div>").prependTo(".first-core-paragraph p");

  $(".search-icon-wrapper > a").click(function(e) {
    e.preventDefault();
    $(".header-search-form").toggleClass("search-active");
  });
});
