import $ from "jquery";

$(document).ready(function() {
  $(".filter-pill-button").click(function() {
    if ($(this).hasClass("active-filter")) {
      $(this).removeClass("active-filter");
    } else {
      $(this).addClass("active-filter");
    }
  });

  $(".filter-button").click(function() {
    var categorySlugArray = [];
    var typeSlugArray = [];
    $(".single-category.active-filter").each(function() {
      var activeFilter = $(this).find("button");
      var filterSlug = activeFilter.data("category-slug");
      categorySlugArray.push(filterSlug);
    });

    $(".single-type.active-filter").each(function() {
      var activeFilter = $(this).find("button");
      var filterSlug = activeFilter.data("type-slug");
      typeSlugArray.push(filterSlug);
    });

    var categorySlugString = categorySlugArray.toString();
    var typeSlugString = typeSlugArray.toString();
    var url = "";

    if (categorySlugString !== "" && typeSlugString !== "") {
      url =
        "/resources/library/?types=" +
        typeSlugString +
        "&resource-categories=" +
        categorySlugString;
    } else if (categorySlugString !== "" && typeSlugString == "") {
      url = "/resources/library/?resource-categories=" + categorySlugString;
    } else if (categorySlugString == "" && typeSlugString !== "") {
      url = "/resources/library/?types=" + typeSlugString;
    } else {
      url = "/resources/library/";
    }
    window.location.replace(url);
  });

  $(".single-resource-copy a").popover();

  $(".single-resource-copy > a").click(function(e) {
    var resourceURL = $(this).data("href");
    var element = $(
      "<input class='copy-text-holder' type='text' height='0px' width='0px' value='" +
        resourceURL +
        "' />"
    ).appendTo("body");
    element[0].select();
    document.execCommand("copy");
    element.remove();

    setTimeout(function() {
      $(".single-resource-copy > a").popover("hide");
    }, 2000);
  });
});
