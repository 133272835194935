import $ from "jquery";

$(document).ready(function() {
  //"section[id^=anchor-]"
  $(".header-button-wrapper a").click(function() {
    var location = $(this).attr("href");
    $("html, body").animate(
      {
        scrollTop: $(location).offset().top - 150
      },
      500
    );
  });
});
